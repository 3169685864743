import React from "react"
import Layout from "../../components/layout/Layout.js"
import { BlogRow, Row } from "../../components/row/Row.js"
import {
  SubHeading,
  BlogTitle,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/content-governance-template.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium } from "../../components/text/Text.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"


function ContentLifecycleTemplate({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Content Lifecycle Process - Template" 
       description="This template details the content lifecycle stages, from ideation and topic research to creation, distribution and optimization."
       image={img1}
       featuredImage={img1} />
      
      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/content-lifecycle-template/">Content Lifecycle Process - Template</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle title="Content Lifecycle Process - Template" />
            <SubHeading subheading="This template details the content lifecycle stages, from ideation and topic research to creation, distribution and optimization." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/content-lifecycle-process" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Content Lifecycle Process Template" />
          </Column50>
        </Row>
      </AboveFold>
      
      
      <Section isCentered>
        <BlogRow isCentered>
          <TextMedium textMedium="This template details the content lifecycle stages, from ideation and topic research to creation, distribution and optimization."/>
          <GumroadForm  
                formName="Content lifecycle template"
                redirectPage="https://socontent.gumroad.com/l/content-lifecycle-process"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/content-lifecycle-process"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
            />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentLifecycleTemplate
